export const REDIRECT_API_BASE_URL= window.location.origin;

export const LOGIN_API_BASE_URL= window?._env_?.backendUrl
    ? window._env_.backendUrl : window?._env_?.backendUrl;

export const IDP_URL_VALUE = window?._env_?.idpUrl
    ? window._env_.idpUrl : window?._env_?.idpUrl;
export const ENV_TYPE =  window?._env_?.env
    ? window._env_.env : window?._env_?.env;

export const ROBOTS_CONTENT =  window?._env_?.robotsContent
    ? window._env_.robotsContent : window?._env_?.robotsContent;

export const SNOMED_CT_ID_COMMA_SEPERATED_STRING =  window?._env_?.snomedCtId
    ? window._env_.snomedCtId : window?._env_?.snomedCtId;

export const ACCESS_TOKEN = 'accessToken';
export const ACCESS_TOKEN_NOT_FOUND_MESSAGE = "No access token set.";
export const REFRESH_TOKEN = 'refreshToken';

export const USER = 'user';
export const SECTORS = 'sectors';
export const SEARCHRESULT_ID = 'search_result_id';

export const SYSTEM_ADMIN = "System Admin "

export const ROLE_ADMIN= "ROLE_ADMIN";
export const ROLE_USER= "ROLE_USER";

export const ROLES= [ROLE_ADMIN,ROLE_USER];

export const OAUTH2_REDIRECT_URI = REDIRECT_API_BASE_URL + '/oauth2/redirect'

export const INERA_IDP_AUTH_URL = LOGIN_API_BASE_URL + '/oauth2/authorize/ineraidp?redirect_uri=' + OAUTH2_REDIRECT_URI;

export const LAST_UPDATED = '_lastUpdated';

export const VERTICAL_BAR_URL_ENCODING = "|";