import React, {useRef, useState} from "react";
import '@inera/ids-core/styles/themes/inera-admin.css';
import '@inera/ids-core/styles/themes/inera.css';
import {
    IDSHeaderAvatar,
    IDSHeader,
    IDSHeaderItem,
    IDSHeaderNav,
    IDSHeaderNavItem,
    IDSLink,
    IDSHeaderMobileMenu,
    IDSMobileMenuItem,
    IDSIconUser
} from "@inera/ids-react";
import {useLocation} from "react-router-dom";
import {INERA_IDP_AUTH_URL, SYSTEM_ADMIN, ENV_TYPE} from "../../../../constants";
import {codesystem, valueset, conceptmap} from "../../../../model/defaultvalues/Constant";
import {ROLE_ADMIN} from "../../../../constants";
import CreateDialog from "../create/CreateDialog";

/*
* TODO: ConceptMap
* */
export const Header = (props) => {

    const location = useLocation();
    const getSectorName = (selectedRole) => {

        for (let role of props.currentUser.roles) {
            if (role.roleType === selectedRole) {
                for (let authority of role.authorities) {
                    if (authority.sector.code === props.currentUser.selectedSectorCode) {
                        if (selectedRole === ROLE_ADMIN) {
                            return SYSTEM_ADMIN + authority.sector.title;
                        } else {
                            return authority.sector.title;
                        }
                    }
                }
            }
        }
    }

    const [showCreateDialogCodeSystem, setShowCreateDialogCodeSystem] = useState(false);
    const [showCreateDialogValueSet, setShowCreateDialogValueSet] = useState(false);
    /*    const [showCreateDialogConceptMap, setShowCreateDialogConceptMap] = useState(false);*/

    const createButtonRefCodeSystem = useRef("create-button-codesystem");
    const createButtonRefValueSet = useRef("create-button-valueset");
    /*    const createButtonRefConceptMap = useRef("create-button-conceptmap");*/

    const fireEvent = (el, eventName) => {
        const event = new Event(eventName, {bubbles: true});
        el.dispatchEvent(event);
    };

    const createCodeSystem = () => {
        setShowCreateDialogCodeSystem(true);
        setShowCreateDialogValueSet(false);
        /*        setShowCreateDialogConceptMap(false);*/
        createButtonRefCodeSystem.current.click()
    };

    const createValueSet = () => {
        setShowCreateDialogValueSet(true);
        setShowCreateDialogCodeSystem(false);
        /*        setShowCreateDialogConceptMap(false);*/
        createButtonRefValueSet.current.click()
    };

    /*    const createConceptMap = () => {
            setShowCreateDialogConceptMap(true);
            setShowCreateDialogCodeSystem(false);
            setShowCreateDialogValueSet(false);
            createButtonRefConceptMap.current.click()
        };*/

    const getIDSMobileMenuItemLoggedInDetails = () => {
        return (
            <IDSMobileMenuItem headline={props.currentUser.name + ", " + getSectorName(props.currentUser.selectedRole)}
                               active="">
                <IDSMobileMenuItem active="">
                    <a href="#" onClick={() => {
                        props.clickButtonRef()
                    }}>
                        Byt sektor</a>
                </IDSMobileMenuItem>
                <IDSMobileMenuItem active="">
                    <a href="#" onClick={() => {
                        props.onLogout(true)
                    }}>
                        Logga ut</a>
                </IDSMobileMenuItem>
            </IDSMobileMenuItem>);
    }


    const getIDSMobileMenuItemLoggedIn = () => {
        return (
            <IDSMobileMenuItem active="">
                <a href={INERA_IDP_AUTH_URL + "&location=" + location.pathname}>
                    Logga in</a>
            </IDSMobileMenuItem>
        )
    }

    const getIDSMobileMenuItemSearchArtefact = () => {
        return (
            <IDSMobileMenuItem active="">
                <a href="/search"> Sök artefakt </a>
            </IDSMobileMenuItem>
        )
    }


    return (

        <div>
            <IDSHeader
                brandtext="Terminologitjänsten"
                type="inera-admin">
                {props.currentUser == null ?
                    <div>
                        <IDSHeaderItem>
                            <IDSIconUser></IDSIconUser>
                            <a href={INERA_IDP_AUTH_URL + "&location=" + location.pathname}>
                                Logga in</a>
                        </IDSHeaderItem>
                        {(ENV_TYPE === "dev" || ENV_TYPE === "test") &&
                            <div>
                                <IDSHeaderItem>
                                    <IDSIconUser></IDSIconUser>
                                    <a href={"/test/login"}>
                                        Logga in med testanvändare</a>
                                </IDSHeaderItem>
                            </div>
                        }
                    </div> :
                    <IDSHeaderItem/>
                }

                {props.currentUser == null ?
                    <IDSHeaderAvatar/> :
                    <IDSHeaderAvatar username={props.currentUser.name}
                                     unit={props.currentUser.selectedSectorCode === undefined ? "Sektor" :
                                         getSectorName(props.currentUser.selectedRole)}>
                        <div slot="dropdown">
                            <IDSLink block="true" className="ids-mb-5 ids-mt-2">
                                <a href="#" onClick={() => {
                                    props.clickButtonRef()
                                }}>
                                    Byt sektor</a>
                            </IDSLink>
                            <hr/>
                            <IDSLink block="true" icon="user" className="ids-mt-5">
                                <a href="#" onClick={() => {
                                    props.onLogout(true)
                                }}>
                                    Logga ut</a>
                            </IDSLink>
                        </div>
                    </IDSHeaderAvatar>

                }

                <IDSHeaderNav>
                    <IDSHeaderNavItem link>
                        <a href="/search"> Sök artefakt </a>
                    </IDSHeaderNavItem>

                    <CreateDialog createButtonRef={createButtonRefCodeSystem} artifactType={codesystem}
                                  showCreateDialog={showCreateDialogCodeSystem}
                                  setShowCreateDialog={setShowCreateDialogCodeSystem}/>
                    <CreateDialog createButtonRef={createButtonRefValueSet} artifactType={valueset}
                                  showCreateDialog={showCreateDialogValueSet}
                                  setShowCreateDialog={setShowCreateDialogValueSet}/>
                    {/*         <CreateDialog createButtonRef={createButtonRefConceptMap} artifactType={conceptmap}
                                  showCreateDialog={showCreateDialogConceptMap}
                                  setShowCreateDialog={setShowCreateDialogConceptMap}/>*/}

                    {(props.authenticated && (props.currentUser.selectedRole !== ROLE_ADMIN)) &&
                        <IDSHeaderNavItem label="Skapa artefakt">
                            <IDSLink color="var(--header-nav-item-link_color)" slot="col-1">
                                <ids-icon color="var(--color-main)" name="arrow"></ids-icon>
                                <a href="#" onClick={() => {
                                    createCodeSystem()
                                }}>Kodverk </a>
                            </IDSLink>
                            <IDSLink color="var(--header-nav-item-link_color)" slot="col-1">
                                <ids-icon color="var(--color-main)" name="arrow"></ids-icon>
                                <ids-icon color="var(--color-main)" name="arrow"></ids-icon>
                                <a href="#" onClick={() => {
                                    createValueSet();
                                }}>Urval </a>
                            </IDSLink>
                            {/*             <IDSLink color="var(--header-nav-item-link_color)" slot="col-1">
                                <ids-icon color="var(--color-main)" name="arrow"></ids-icon>
                                <a href="#" onClick={() => {
                                    createConceptMap();
                                }}>Mappning </a>
                            </IDSLink>*/}
                        </IDSHeaderNavItem>
                    }

                    {(props.authenticated && props.currentUser.selectedRole === ROLE_ADMIN) &&
                        <IDSHeaderNavItem link>
                            <a href="/CodeSystem/terminologyservice-sector-tags"> Sektorer </a>
                        </IDSHeaderNavItem>
                    }
                </IDSHeaderNav>

                {(props.authenticated == false) && (ENV_TYPE !== "dev" && ENV_TYPE !== "test" && ENV_TYPE !== undefined) &&
                    <IDSHeaderMobileMenu expanded="">
                        {getIDSMobileMenuItemSearchArtefact()}
                        {getIDSMobileMenuItemLoggedIn()}
                    </IDSHeaderMobileMenu>
                }

                {(props.authenticated == false) && (ENV_TYPE === "dev" || ENV_TYPE === "test" || ENV_TYPE === undefined) &&
                    <IDSHeaderMobileMenu expanded="">
                        {getIDSMobileMenuItemSearchArtefact()}
                        {getIDSMobileMenuItemLoggedIn()}
                        <IDSMobileMenuItem active="">
                            <a href={"/test/login"}>
                                Logga in med testanvändare</a>
                        </IDSMobileMenuItem>
                    </IDSHeaderMobileMenu>
                }

                {(props.authenticated && (props.currentUser.selectedRole !== ROLE_ADMIN)) &&
                    <IDSHeaderMobileMenu expanded="">
                        {getIDSMobileMenuItemLoggedInDetails()}
                        {getIDSMobileMenuItemSearchArtefact()}
                        <IDSMobileMenuItem headline="Skapa Artefakt" active="">

                            <IDSMobileMenuItem active="">
                                <a href="#" onClick={() => {
                                    createCodeSystem()
                                }}>Kodverk </a>
                            </IDSMobileMenuItem>
                            <IDSMobileMenuItem active="">
                                <a href="#" onClick={() => {
                                    createValueSet();
                                }}>Urval </a>
                            </IDSMobileMenuItem>
                            {/*                            <IDSMobileMenuItem active="">
                                <a href="#" onClick={() => {
                                    createConceptMap();
                                }}>Mappning </a>
                            </IDSMobileMenuItem>*/}
                        </IDSMobileMenuItem>
                    </IDSHeaderMobileMenu>
                }

                {(props.authenticated && (props.currentUser.selectedRole === ROLE_ADMIN)) &&
                    <IDSHeaderMobileMenu expanded="">
                        {getIDSMobileMenuItemLoggedInDetails()}
                    </IDSHeaderMobileMenu>
                }
            </IDSHeader>
        </div>
    );
};
