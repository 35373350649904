import React from "react";
import Client from 'fhir-kit-client';
import {
    fhirUrl,
    SNOMED_CT_URL
} from "../../model/defaultvalues/Constant";
import {Parameters} from "fhir-typescript-models/dist/src/models/fhir/internal";


const fhirClient = new Client({baseUrl: fhirUrl});

export const lookupSnomedCode = async (code: string, version : string) => {

    let conceptToReturn = undefined;
    await fhirClient.request("CodeSystem/$lookup?code=" + code +
        "&system=" + SNOMED_CT_URL +
        "&version=" + version +
    "&property=" +
 /*    "*"*/
           "effectiveTime&property=moduleId&property=inactive&property=sufficientlyDefined&property=code&property=fullySpecifiedName&property=display&property=kind&property=code&property=terms&property=codingNote&property=exclusion&property=abstract&property=postcoordinationScale&property=artgIds&property=display&property=designation"
/*                " + &property=http://id.who.int/icd11/mms/designation|term&property=inactive&property=property"*/
    )
        .then(response => {
            let responseParameters: Parameters = response as unknown as Parameters;
            let responseCode = undefined;
            let responseDisplay = undefined;
            let responseSwedishRecommended = undefined;
            let responseSwedishDesignation = [];
            for (const element of responseParameters.parameter!) {

                // @ts-ignore
                if (element.name !== undefined && element.name === "code") {
                    // @ts-ignore
                    responseCode = element.valueCode;
                }
                // @ts-ignore
                else if (element.name !== undefined && element.name === "display") {
                    // @ts-ignore
                    responseDisplay = element.valueString;
                }
                // @ts-ignore
                else if (element.name === "designation") {
                    let swedish = false;
                    let swedishRecommendedValue = undefined;
                    let swedishSynonymValue = undefined;
                    let recommended = false;
                    let synonym = false;
                    for (const part of element.part!) {
                        // @ts-ignore
                        if (part.name === "language" && (part.valueCode === "sv-x-sctlang-46011000-052107" || part.valueCode === "sv")) {
                            swedish = true;
                        }
                        // @ts-ignore
                        else if (swedish && part.name === "use" && part.valueCoding !== undefined && part.valueCoding.code === "preferredForLanguage") {
                            // @ts-ignore
                            recommended = true;
                        }
                        // @ts-ignore
                        else if (swedish && part.name === "use" && part.valueCoding !== undefined && part.valueCoding.code === "900000000000013009") {
                            // @ts-ignore
                            synonym = true;
                        }
                        // @ts-ignore
                        else if (swedish && recommended && part.name === "value") {
                            // @ts-ignore
                            swedishRecommendedValue = part.valueString;
                            recommended= false;
                        }
                        // @ts-ignore
                        else if (swedish && synonym && part.name === "value") {
                            // @ts-ignore
                            swedishSynonymValue = part.valueString;
                            synonym= false;
                        }
                    }

                    if (swedish == true && swedishRecommendedValue !== undefined) {
                        responseSwedishRecommended = swedishRecommendedValue;

                    }
                    if (swedish == true && swedishSynonymValue !== undefined) {
                        responseSwedishDesignation.push(swedishSynonymValue)
                    }
                }
            }

            const concept = {
                "code": responseCode,
                "display": responseSwedishRecommended ? responseSwedishRecommended : responseDisplay,
                "swedishSynonym" : responseSwedishDesignation,
                "swedishDesignation": responseSwedishRecommended
            }

            conceptToReturn = concept;

        })
        .catch(e => {
            console.error("FhirClient SNOMED handleRowClickShowConceptData Error Response" + JSON.stringify(e));
            return undefined;
        });

    return conceptToReturn;

};